import React from "react";

import * as styles from "./ArrowDown.module.scss";

const ArrowDown = () => (
    <div className={styles.root}>
        <svg xmlns="http://www.w3.org/2000/svg" width="11.364" height="266.493">
            <path data-name="Pfad 5" d="M0 258.739V0" transform="rotate(180 2.75 129.37)" style={{
                fill: "none",
                stroke: "#5cb3b8"
            }}/>
            <g data-name="Polygon 1" style={{fill:"#5cb3b8"}}>
                <path d="M10.396 7.5H.968L5.682.863 10.396 7.5z" style={{stroke:"none"}} transform="rotate(180 5.682 133.246)"/>
                <path d="M5.682 1.727 1.937 7h7.49L5.682 1.727m0-1.727 5.682 8H0l5.682-8z" style={{fill:"#5cb3b8",stroke:"none"}} transform="rotate(180 5.682 133.246)"/>
            </g>
        </svg>
    </div>
);

export default ArrowDown;