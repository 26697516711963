import * as React from "react"
import {graphql} from "gatsby";
import {I18nContext, useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import List from "../components/molecules/List/List";
import StageTeaser from "../components/molecules/StageTeaser/StageTeaser";
import Text from "../components/atoms/Text/Text";
import ColoredBox from "../components/molecules/ColoredTextBox/ColoredBox";
import GridContainer from "../components/grid/GridContainer";
import HalfWidthCell from "../components/grid/HalfWidthCell";
import Button from "../components/atoms/Button/Button";
import PageReferencesTitle from "../components/atoms/PageReferencesTitle/PageReferencesTitle";
import PageReferencesText from "../components/atoms/PageReferencesText/PageReferencesText";
import Image from "../components/atoms/Image/Image";
import SmallTextCarousel, {SlideType} from "../components/molecules/SmallTextCarousel/SmallTextCarousel";
import TextBubble from "../components/atoms/TextBubble/TextBubble";
import ArrowDown from "../components/atoms/ArrowDown/ArrowDown";
import TranslatedLink from "../components/util/TranslatedLink";
import Seo from "../components/global/Seo/Seo";

type propType = {
	data: any
	classes: any,
	context: any
}
const DiseasePage = ({data}: propType) => {
	const {t} = useTranslation();

	const context = React.useContext(I18nContext);
	const currentLanguage = context.i18n.language;

	return (
		<>
			<Seo title={t("disease:seo.title")} description={t("disease:seo.description")} />
			<FullWidthContainer>
				<StageTeaser image={data.stageTeaser} imageAlt={t('disease:imageAlt')}/>

				<h1>{t('disease:mainHeadline')}</h1>
				<h2>{t('disease:sectionClassification.headline')}</h2>
				<p>
					<Text text="disease:sectionClassification.copy" />
				</p>
				<h3>
					<Text text="disease:sectionClassification.type1.headline" />
				</h3>
				<List list={t('disease:sectionClassification.type1.list', {returnObjects: true})} />


				<h3>
					<Text text="disease:sectionClassification.type2.headline" />
				</h3>
				<List list={t('disease:sectionClassification.type2.list', {returnObjects: true})} />
				
				<h3>
					<Text text="disease:sectionClassification.type3.headline" />
				</h3>
				<List list={t('disease:sectionClassification.type3.list', {returnObjects: true})} />
			</FullWidthContainer>
			<GridContainer className="margin-top-section">
				<HalfWidthCell expandforSmallOnly={true}>
					<h2 className="margin-top-0">{t('disease:sectionEpidemiology.headline')}</h2>
					<p>
						<Text text="disease:sectionEpidemiology.copy" />
					</p>
					<List list={t('disease:sectionEpidemiology.list', {returnObjects: true})} />
				</HalfWidthCell>
				<HalfWidthCell expandforSmallOnly={true}>
					<Image imagePath={"/assets/images/disease/" + currentLanguage + "/epidemiology.svg"} imageAlt={t("disease:sectionEpidemiology.imageAltText")} />
				</HalfWidthCell>
			</GridContainer>
			<ColoredBox>
				<FullWidthContainer>
					<h2>{t('disease:sectionPathophysiology.headline')}</h2>
					<p>
						<Text text="disease:sectionPathophysiology.copy" />
					</p>
				</FullWidthContainer>
			</ColoredBox>
			<FullWidthContainer>
				<h2 className="margin-top-0">{t('disease:sectionDelayedDiagnosis.headline')}</h2>
				<p>
					<Text text="disease:sectionDelayedDiagnosis.copy" />
				</p>
			</FullWidthContainer>
			<FullWidthContainer className="hide-on-small">
				<Image className="margin-top-2" imagePath={"/assets/images/disease/" + currentLanguage + "/delayed-diagnosis.svg"} imageAlt={t("disease:sectionDelayedDiagnosis.imageAltText")} caption={t('disease:sectionDelayedDiagnosis.imageCaption')} />
			</FullWidthContainer>
			<FullWidthContainer centerContent={true} className="hide-on-medium-up">
				<TextBubble content={t('disease:sectionDelayedDiagnosis.smallTextBubble')} />
			</FullWidthContainer>
			<FullWidthContainer centerContent={true} className="hide-on-medium-up">
				<ArrowDown />
			</FullWidthContainer>
			<FullWidthContainer className="hide-on-medium-up margin-top-small">
				<SmallTextCarousel slideType={SlideType.CIRCLE} slides={t('disease:sectionDelayedDiagnosis.slider', {returnObjects: true})} />
			</FullWidthContainer>
			<ColoredBox>
				<FullWidthContainer>
					<h2>{t('disease:sectionEarlyDiagnosis.headline')}</h2>
					<p className="margin-top-2">
						<Text text="disease:sectionEarlyDiagnosis.copy" />
					</p>
					<h3 className="margin-top-0">
						<Text text="disease:sectionEarlyDiagnosis.subsectionCytopenia.headline" />
					</h3>
					<List list={t('disease:sectionEarlyDiagnosis.subsectionCytopenia.list', {returnObjects: true})} />
					<h3>
						<Text text="disease:sectionEarlyDiagnosis.subsectionSplenomegalia.headline" />
					</h3>
					<List list={t('disease:sectionEarlyDiagnosis.subsectionSplenomegalia.list', {returnObjects: true})} />
					<h3>
						<Text text="disease:sectionEarlyDiagnosis.subsectionHepatomegalia.headline" />
					</h3>
					<List list={t('disease:sectionEarlyDiagnosis.subsectionHepatomegalia.list', {returnObjects: true})} />
					<h3>
						<Text text="disease:sectionEarlyDiagnosis.subsectionBones.headline" />
					</h3>
					<List list={t('disease:sectionEarlyDiagnosis.subsectionBones.list', {returnObjects: true})} />
					<h3>
						<Text text="disease:sectionEarlyDiagnosis.subsectionRisk.headline" />
					</h3>
					<List list={t('disease:sectionEarlyDiagnosis.subsectionRisk.list', {returnObjects: true})} />
					<h3>
						<Text text="disease:sectionEarlyDiagnosis.subsectionRareDiagnoses.headline" />
					</h3>
					<List list={t('disease:sectionEarlyDiagnosis.subsectionRareDiagnoses.list', {returnObjects: true})} />
				</FullWidthContainer>
			</ColoredBox>
			<FullWidthContainer>
				<TranslatedLink title={t('orderButton.title')} to="/order">
					<Button uppercase={true} inverted={true}>
						{t('disease:sectionReferences.buttonLabel')}
					</Button>
				</TranslatedLink>
				<PageReferencesTitle>
					<Text text="disease:sectionReferences.headline" />
				</PageReferencesTitle>
				<PageReferencesText>
					<Text text="disease:sectionReferences.copy" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="disease:sectionReferences.explanation" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="approvalCode" />
				</PageReferencesText>
			</FullWidthContainer>
		</>
	)
}

export default DiseasePage

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "disease"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		stageTeaser: file(
			relativePath: { eq: "disease/StageTeaserDesktop.jpg"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED, 
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
					sizes: "(min-width: 1300px) 1292px, (min-width: 640px) 100vw,  200vw"
					
				)
			}
		}
	}
`;
